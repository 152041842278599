import React from "react"



const Articles = ({ value }) => (
  <div className="articles block">


    {value && value.map(article => (
      <Article value={article} key={article.id} />
    ))}
  </div>
)

const Article = ({ value }) => {

  return (
    <div className="item">
      <span className="inner">
        {value.link &&
          <h4><a href={value.link} >{value.title}</a></h4>
        }
            {!value.link &&
          <h4 ><span>{value.title}</span></h4>
        }
        
        {value.coauthors && <div class="coauthors">  <span> {value.coauthors}</span></div>}
        <div><span class="publication">{value.publication }</span>
        <span >{value.year}</span>
        
</div>


        </span>
    </div>
  )
}

export default Articles
