import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"

import Articles from "../components/articles.js"
import Courses from "../components/courses.js"
import { Helmet } from "react-helmet"

class PageTemplate extends Component {
  constructor(props) {
    super(props);
    this.currentPage = this.props.data.wordpressPage
    // this.headerImageData = (this.currentPage.acf.background === null) ? null : this.currentPage.acf.background.localFile.childImageSharp.fluid

  }


  render() {
    const currentPage = this.currentPage
    const footerPage = this.props.data.footerPage
    return (
      <Layout footer={footerPage} title={currentPage.title} >
        {/* //  headerImageData={this.headerImageData} */}
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Anna Kovner – " + currentPage.title}</title>
          <link
            rel="canonical"
            href={"https://annakovner.com/" + currentPage.slug}
          />


<meta name="keywords" content={currentPage.acf.keywords || '' } />
<meta name="description" content={currentPage.acf.description || '' } />

        </Helmet>



        {currentPage.slug === "blogs" && currentPage.acf.articles && (
          <div>
            <section className="block publications">
              <h2>Blogs</h2>
              <Articles value={currentPage.acf.articles} />

            </section>
          </div>)}


        {currentPage.slug === "working-papers" && currentPage.acf.articles && (
          <div>
            <section className="block publications">
              <h2>Working Papers</h2>
              <Articles value={currentPage.acf.articles} />

            </section>
          </div>)}


          {currentPage.slug === "cv" &&  (
          <div>
            <section className="block ">
              <h2>CV</h2>
              <div class="articles block">
              <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
              </div>
            </section>
          </div>)}

     

        {currentPage.slug === "articles" && currentPage.acf.articles && (
          <div>
            <section className="block publications">

              <h2>Publications</h2>
              <Articles value={currentPage.acf.articles} />

            </section>




          </div>
        )}

        
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      date(formatString: "MMMM DD, YYYY")
      acf {
        keywords,
        description,

        
        

        courses {
          course_name
          school
          dates
          syllabus
        }

        articles {
          title
          publication
          coauthors
          year
          link
        }

      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }

    footerPage: wordpressPage(slug: { eq: "contact" }) {
      title
      content
      slug
    }
  }
`
